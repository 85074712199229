<template>
  <b-row>
    <b-col cols="12">
      <b-nav pills fill>
        <b-nav-item
          v-for="(item, index) in marketplaceItems"
          :key="index"
          :active="$route.name === item.routeName"
          :to="{ name: item.routeName }"
          :disabled="item.disabled"
          :title="
            item.disabled ? 'Belum Tersedia' : 'Order ' + item.orderStatus
          "
          :style="{ cursor: item.disabled ? 'not-allowed' : 'pointer' }"
        >
          <font-awesome-icon
            :icon="['fas', item.fontAwesomeIcon]"
            :class="$route.name === item.routeName ? '' : item.color"
          >
          </font-awesome-icon>

          {{ item.orderStatus }}
        </b-nav-item>
      </b-nav>
    </b-col>
    <b-col cols="12">
      <transition :name="routerTransition" mode="out-in">
        <router-view />
      </transition>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BNav, BNavItem, BImg } from 'bootstrap-vue';
import { defineComponent } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';

export default defineComponent({
  name: 'Order',
  components: {
    BRow,
    BCol,
    BCard,
    BNav,
    BNavItem,
    BImg,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();

    return {
      routerTransition,
      contentWidth,
    };
  },
  data() {
    return {
      marketplaceItems: [
        {
          routeName: 'OrdersNew',
          orderStatus: 'Baru',
          fontAwesomeIcon: 'shopping-cart',
          color: 'color-new',
        },
        {
          routeName: 'OrdersAccepted',
          orderStatus: 'Diterima',
          fontAwesomeIcon: 'box',
          color: 'color-accecpted',
        },
        {
          routeName: 'OrdersReadyToShip',
          orderStatus: 'Siap Kirim',
          fontAwesomeIcon: 'boxes-packing',
          color: 'color-ready-to-ship',
        },
        {
          routeName: 'OrdersShipping',
          orderStatus: 'Pengiriman',
          fontAwesomeIcon: 'truck',
          color: 'color-shipping',
        },
        {
          routeName: 'OrdersDelivered',
          orderStatus: 'Terkirim',
          fontAwesomeIcon: 'print',
          color: 'color-in-shipping',
        },
        {
          routeName: 'OrdersFinished',
          orderStatus: 'Selesai',
          fontAwesomeIcon: 'check-to-slot',
          color: 'text-success',
        },
        {
          routeName: 'OrdersCancelled',
          orderStatus: 'Dibatalkan',
          fontAwesomeIcon: 'ban',
          color: 'text-danger',
        },
      ],
    };
  },
});
</script>

<style lang="scss">
.color-accecpted {
  color: #28a745;
}

.color-rejected {
  color: #dc3545;
}

.color-ready-to-ship {
  color: #ffc107;
}

.color-shipping {
  color: #007bff;
}

.color-in-shipping {
  color: #17a2b8;
}

.color-new {
  color: #e038c9;
}
</style>
